<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12"
        >
          <div class="headline text-center justify-center">
            Tạo câu hỏi
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </v-col
        >
        <v-col cols="12"
        >
          <div class="headline text-center justify-center">
            Cấp độ:<span class="font-weight-black">{{
              exam_meta.level_text
            }}</span>
          </div>
        </v-col
        >
      </v-row>
      <div class="add-btn-wrapper">
        <h3>Danh sách đề thi</h3>
        <div class="add-btn">
          <v-btn color="primary" @click="openDialog"
          >Tạo đề thi mới
          </v-btn>
        </div>
      </div>
      <v-data-table :headers="headers" :items="exams" class="elevation-1">
        <template v-slot:item.title="{ item }">
          <div v-html="item.title"></div>
        </template>
        <template v-slot:item.group_question_type_id="{ item }">
          <div v-if="item.group_question_type_id === 1">Nhóm chung</div>
          <div v-else-if="item.group_question_type_id === 2">Nhóm riêng</div>
        </template>
        <template v-slot:item.is_clone="{ item }">
          <div class="label-btn" v-if="item.is_clone === 1">
            <v-btn outlined depressed color="primary">Tạo tự động</v-btn>
          </div>
          <div class="label-btn" v-else-if="item.group_question_type_id !== 1">
            <v-btn outlined color="success">Tạo thủ công</v-btn>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="ml-lg-5"
              small
              @click="deleteExam(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.question="{ item }">
          <v-btn
              small
              class="mr-2"
              @click="viewSelectQuestion(item)"
          >
            Thêm câu hỏi
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog
          v-model="dialog"
          max-width="500px"
      >
        <ValidationObserver ref="form_create_group" v-slot="{ handleSubmit }">
          <v-form
              @submit.prevent="handleSubmit(saveExam)"
              method="post"
              id="save-exam-form"
          >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="12"
                    >
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <v-text-field
                            :disabled="editedItem.id"
                            v-model="editedItem.slug"
                            label="Slug"
                        ></v-text-field>
                        <div class="validate-error text-danger text-danger">
                          {{ errors[0] }}
                        </div>
                      </ValidationProvider>

                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="12"
                    >
                      <v-select
                          v-model="editedItem.exam_config_id"
                          :items="examConfigByLevel"
                          item-text="name"
                          item-value="id"
                          label="Cấu hình bài thi"
                          solo
                      ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="12"
                    >
                      <v-select
                          :disabled="editedItem.id"
                          v-model="editedItem.is_clone"
                          :items="createConfig"
                          item-text="name"
                          item-value="id"
                          label="Cách tạo"
                          solo
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    type="submit"
                    color="blue darken-1"
                    form="save-exam-form"
                    text
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_ALL_EXAM_CONFIG,
  GET_EXAM_LIST,
  GET_EXAM_META_DETAIL,
  SAVE_EXAM,
  GENERATE_EXAM,
  UPDATE_EXAM,
  DELETE_EXAM,
  SET_CHECKED_QUESTION,
  SET_GROUP_QUESTION,
  GET_EXAM_CONFIG_BY_LEVEL,
} from "@/store/exams.module";
import {SUCCESS} from "@/store/alerts.module";
import {SET_QUESTION_CHECKED, SET_SELECTED_QUESTION} from "@/store/questions.module";
import {SET_GROUP_QUESTION_LIST_BY_CONFIG} from "@/store/group-questions.module";
import mixin from '@/store/mixin'

export default {
  name: "select-type",
  mixins:[mixin],
  data() {
    return {
      headers: [
        {text: "Mã bài thi", value: "id"},
        {text: "slug", value: "slug"},
        {text: "Loại đề", value: "is_clone"},
        {text: "Loại bài thi", value: "exam_config.name"},
        {text: "Số câu ", value: "exam_question_count"},
        {text: "Thao tác ", value: "actions"},
        {text: "", value: "question"},
      ],
      createConfig: [
        {
          id: 0,
          name: "Tạo thủ công"
        },
        {
          id: 1,
          name: "Tạo tự động"
        }
      ],
      editedItem: {
        slug: "",
        exam_meta_id: this.$route.query.exam_meta_id,
        is_clone: 0,
        exam_config_id: null,
      },
      dialog: false,

    }
  },
  computed: {
    ...mapGetters({
      // exam: "examDetail",
      exams: "examList",
      exam_meta: "examMetaDetail",
      examConfig: "examConfigList",
      examConfigByLevel: "examConfigListByLevel",
    }),
    formTitle() {
      return "Tạo mới đề thi"
    }
  },
  async created() {
    await this.$store.commit(SET_GROUP_QUESTION_LIST_BY_CONFIG,[]);
    await this.$store.commit(SET_CHECKED_QUESTION,[]);
    await this.$store.commit(SET_GROUP_QUESTION,[]);
    await this.$store.commit(SET_QUESTION_CHECKED,[]);
    await this.$store.commit(SET_SELECTED_QUESTION,[]);
    await this.$store.dispatch(GET_ALL_EXAM_CONFIG);
    await this.$store.dispatch(GET_EXAM_META_DETAIL, {
      exam_meta_id: this.$route.query.exam_meta_id,
    });
    await this.$store.dispatch(GET_EXAM_CONFIG_BY_LEVEL, {
      level: this.exam_meta.level,
    });
    await this.fetchExamList();
  },
  watch: {
    exam_meta: {
      handler(self) {
        if (self.level === 1) {
          self.level_text = "TOPIK I";
        }
        if (self.level === 2) {
          self.level_text = "TOPIK II";
        }
      },
    },
  },
  methods: {
    fetchExamList() {
      this.$store.dispatch(GET_EXAM_LIST, {
        exam_meta_id: this.$route.query.exam_meta_id,
      });
    },
    openDialog() {
      this.dialog = true;
      this.editedItem = {
        slug: "",
        exam_meta_id: this.$route.query.exam_meta_id,
        is_clone: 0,
        exam_config_id: null,
      }
    },
    selectExam(item) {
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },
    saveExam() {
      this.editedItem.formatted_slug = ( this.sanitizeTitle(this.editedItem.slug));
      if (this.editedItem.is_clone === 1) {
        this.$store.dispatch(GENERATE_EXAM,
            {
              exam: this.editedItem
            }).then((data) => {
          if (data.status) {
            this.dialog = false;
            this.fetchExamList();
          } else {
            this.$store.dispatch(SUCCESS, {
              type: "error_vote",
              message: data.message
            }, {
              root: true,
            });
          }
        });
      } else if (!this.editedItem.id) {
        this.$store.dispatch(SAVE_EXAM,
            {
              exam: this.editedItem
            }).then((data) => {
          if (data.status) {
            this.dialog = false;
            this.fetchExamList();
          } else {
            this.$store.dispatch(SUCCESS, {
              type: "error_vote",
              message: data.message
            }, {
              root: true,
            });
          }
        });
      } else {
        this.$store.dispatch(UPDATE_EXAM,
            {
              exam: this.editedItem
            }).then((data) => {
          if (data.status) {
            this.dialog = false;
            this.fetchExamList();
          } else {
            this.$store.dispatch(SUCCESS, {
              type: "error_vote",
              message: data.message
            }, {
              root: true,
            });
          }
        });
      }
    },
    async deleteExam(item) {
      if (confirm("Bạn có chắc chắn muốn xóa không?")) {
        await this.$store.dispatch(DELETE_EXAM, item.id);
        await this.fetchExamList();
      }
    },
    close() {
      this.dialog = false;
    },
    viewSelectQuestion(item) {
      this.$router.push({
        path: "/exam/select-question",
        query: {
          exam_id: item.id,
          exam_config_id: item.exam_config.id
        },
      });
    }
  }
}
</script>

<style lang="scss">
.add-btn-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 10px;

  .add-btn {
    display: flex;
    justify-content: flex-end;

    button {
      width: 200px;
    }
  }

}

</style>
