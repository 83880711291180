var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headline text-center justify-center"},[_vm._v(" Tạo câu hỏi "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headline text-center justify-center"},[_vm._v(" Cấp độ:"),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.exam_meta.level_text))])])])],1),_c('div',{staticClass:"add-btn-wrapper"},[_c('h3',[_vm._v("Danh sách đề thi")]),_c('div',{staticClass:"add-btn"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.openDialog}},[_vm._v("Tạo đề thi mới ")])],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.exams},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.title)}})]}},{key:"item.group_question_type_id",fn:function(ref){
var item = ref.item;
return [(item.group_question_type_id === 1)?_c('div',[_vm._v("Nhóm chung")]):(item.group_question_type_id === 2)?_c('div',[_vm._v("Nhóm riêng")]):_vm._e()]}},{key:"item.is_clone",fn:function(ref){
var item = ref.item;
return [(item.is_clone === 1)?_c('div',{staticClass:"label-btn"},[_c('v-btn',{attrs:{"outlined":"","depressed":"","color":"primary"}},[_vm._v("Tạo tự động")])],1):(item.group_question_type_id !== 1)?_c('div',{staticClass:"label-btn"},[_c('v-btn',{attrs:{"outlined":"","color":"success"}},[_vm._v("Tạo thủ công")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-lg-5",attrs:{"small":""},on:{"click":function($event){return _vm.deleteExam(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.question",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewSelectQuestion(item)}}},[_vm._v(" Thêm câu hỏi ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form_create_group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"save-exam-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveExam)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.editedItem.id,"label":"Slug"},model:{value:(_vm.editedItem.slug),callback:function ($$v) {_vm.$set(_vm.editedItem, "slug", $$v)},expression:"editedItem.slug"}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-select',{attrs:{"items":_vm.examConfigByLevel,"item-text":"name","item-value":"id","label":"Cấu hình bài thi","solo":""},model:{value:(_vm.editedItem.exam_config_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "exam_config_id", $$v)},expression:"editedItem.exam_config_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-select',{attrs:{"disabled":_vm.editedItem.id,"items":_vm.createConfig,"item-text":"name","item-value":"id","label":"Cách tạo","solo":""},model:{value:(_vm.editedItem.is_clone),callback:function ($$v) {_vm.$set(_vm.editedItem, "is_clone", $$v)},expression:"editedItem.is_clone"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","color":"blue darken-1","form":"save-exam-form","text":""}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }